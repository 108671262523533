export function  createPeerConnection(address) {
    let PeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
    let iceConfig = {
            iceServers: [
                {
                    urls: `turn:${address}:3478?transport=tcp`,
                    username: 'test',
                    credential: 'test'
                }
            ]
        };
    return new PeerConnection(iceConfig);
}

export async function openMediaDevice() {
    let constraints = {"video": false, "audio": true}
    try {
        console.log('麥克風連接成功')
        let stream = await navigator.mediaDevices.getUserMedia(constraints)
        return stream
    } catch(e) {
        console.log('麥克風連接失敗')
        return null
    }
}