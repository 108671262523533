<template>
  <portal to="user-info-modal">
    <div class="user-info-modal" @click="closeModal"></div>
    <div class="user-info-wrap">
      <div class="title-wrap">
        <div class="account">{{ $t('account_title') }}</div>
        <!-- <i class="el-icon-close" @click="closeModal"/> -->
        <div v-if="!bEdit" class="edit-pen" @click="showEditInput">
          <i class="fa fa-pen"></i>
        </div>
      </div>

      <div class="content-wrap">
        <div class="info top">
          <!-- <div v-if="!bEdit" class="edit-pen" @click="showEditInput">
            <img src="@/assets/icons/account-edit.svg"/>
          </div> -->
          <!-- 帳號 -->
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-user.svg" />
            </div>
            <!-- <input v-if="bEdit" type="text" placeholder="Account" v-model="userId"/> -->
            <div>{{ getUserInfo.id }}</div>
          </div>
          <!-- 使用者角色/群組 -->
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-role.svg" />
            </div>
            <!-- <input v-if="bEdit" type="text" placeholder="Role/Group" v-model="roleId"/> -->
            <div>{{ userRole.name }}</div>
          </div>
          <!-- 使用者名稱 -->
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-name.svg" />
            </div>
            <input
              v-if="bEdit"
              type="text"
              maxlength="20"
              :placeholder="$t('left_bar_userinfo_username_placeholder')"
              v-model="userName"
            />
            <div v-else>{{ getUserInfo.name }}</div>
          </div>
          <!-- Email -->
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-email.svg" />
            </div>
            <input
              v-if="bEdit"
              type="text"
              :placeholder="$t('left_bar_userinfo_email_placeholder')"
              v-model="email"
            />
            <div v-else>{{ getUserInfo.email }}</div>
          </div>
          <!-- 使用者電話號碼 -->
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-phone.svg" />
            </div>
            <input
              v-if="bEdit"
              type="text"
              :placeholder="$t('left_bar_userinfo_phone_placeholder')"
              v-model="userPhone"
            />
            <div v-else>{{ getUserInfo.phone }}</div>
          </div>
          <!-- 語系 -->
          <div class="info-item">
            <div class="icon"><img src="@/assets/icons/language.svg"/></div>
            <el-select
              v-if="bEdit"
              v-model="language"
              class="select"
              :placeholder="$t('search_please_choose')"
            >
              <el-option
                v-for="item in languageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div v-else>{{ $t(`lang_${getUserInfo.language}`) }}</div>
          </div>
          <!-- 時區 -->
          <div class="info-item">
            <div class="icon"><img src="@/assets/icons/timezone.svg"/></div>
            <el-select
              v-if="bEdit"
              v-model="timezone"
              class="select"
              :placeholder="$t('search_please_choose')"
            >
              <el-option
                v-for="item in timezoneOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div v-else>{{ getUserInfo.timezone }}</div>
          </div>
          <!-- 取消/儲存 -->
          <div v-if="bEdit" class="top-btn">
            <div class="btn-wrap cancel-btn" @click="hideEditInput">
              {{ $t('cancel') }}
            </div>
            <div class="btn-wrap confirm-btn" @click="editUser">
              {{ $t('save') }}
            </div>
          </div>
        </div>
        <!-- 變更密碼 -->
        <div class="info middle btn-link" @click="openPSDmodal" v-if="!isNpa">
          <div class="info-item">
            <div class="icon"><img src="@/assets/icons/account-psd.svg" /></div>
            <div>{{ $t('left_ber_userinfo_change_password') }}</div>
          </div>
        </div>
        <!-- 雙因子認證 -->
        <div class="info middle btn-link" @click="openTwoFactorModal" v-if="!isNpa">
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-two-factor.svg" />
            </div>
            <div class="two-factor">
              <span>{{ $t('account_2fa') }}</span>
              <div class="two-factor-st">
                <span v-if="getUserInfo['2faMode'] !== undefined">{{ $t(`account_forced2fa_${getUserInfo['2faMode']}`) }}</span>
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('account_enabled_2fa')/*已啟用雙重驗證*/" placement="bottom">
                  <img v-if="getUserInfo['2faEnabled'] === 1" src="@/assets/icons/success-check.svg" alt="">
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!-- 登出 -->
        <div class="info btm btn-link" @click="openLOGOUTmodal">
          <div class="info-item">
            <div class="icon">
              <img src="@/assets/icons/account-logout.svg" />
            </div>
            <div>{{ $t('account_logout') }}</div>
          </div>
        </div>
      </div>

      <EditPsd v-if="psd_modal_visible" @closemodal="closePSDmodal" />
      <LogOut v-if="logout_modal_visible" @closemodal="closeLOGOUTmodal" />
      <TwoFactor
        v-if="twoFactor_modal_visible"
        :parent="'userinfo'"
        :userInfo="getUserInfo"
        @closemodal="closeTwoFactorModal"
      />
    </div>
  </portal>
</template>

<script>
import moment from 'moment-timezone'
import EditPsd from '@/components/LeftBar/UserInfo/EditPsd'
import LogOut from '@/components/LeftBar/UserInfo/LogOut'
import TwoFactor from '@/components/LeftBar/UserInfo/TwoFactor'
import { mapActions, mapGetters, mapState } from 'vuex'
import { apiEditUser /*, apiLogout*/ } from '@/api/index.js'
import { supportLangs } from '@/i18n/i18n'
import { sysCustomize, getCustomizeValue } from '@/config/others.js'

export default {
  name: 'UserInfo',
  components: {
    EditPsd,
    LogOut,
    TwoFactor
  },
  data() {
    return {
      bEdit: false,
      userId: '',
      userName: '',
      email: '',
      userPhone: '',
      language: '',
      timezone: '',
      psd_modal_visible: false,
      logout_modal_visible: false,
      languageOptions: supportLangs.map(({ key, str }) => {
        return { value: key, label: str }
      }),
      timezoneOptions: [],

      // 雙因子認證
      twoFactor_modal_visible: false,
    }
  },
  computed: {
    ...mapGetters(['getUserInfo']),
    // Note: 'store.userList': 沒有 角色為預設或沒角色 的 登入者資訊..., 所以不能用來找登入者的角色
    ...mapState(['userList', 'isGetWebPreferences', 'customize']),
    userRole() {
      let name = ''

      if (this.getUserInfo) {
        name = this.getUserInfo.roleName
      }

      return { name }
    },
    isNpa() {
      if (this.isGetWebPreferences) {
        // console.log(`[isNpa] isGetWebPreferences, customize:`, this.isGetWebPreferences, this.customize)
        return this.customize === getCustomizeValue(sysCustomize.npa)
      }

      return false
    }
  },
  async mounted() {
    this.getUser()
    this.timezoneOptions = moment.tz.names()
  },
  methods: {
    ...mapActions(['getUser', 'getWebPreferences']),
    closeModal() {
      this.$emit('closeModal')
    },
    showEditInput() {
      this.userId = this.getUserInfo.id
      this.userName = this.getUserInfo.name
      this.email = this.getUserInfo.email
      this.userPhone = this.getUserInfo.phone
      this.language = this.getUserInfo.language
      this.timezone = this.getUserInfo.timezone
      this.bEdit = true
    },
    hideEditInput() {
      this.bEdit = false
    },
    async editUser(){
      const data =  {
        id: this.userId,
        name: this.userName,
        email: this.email,
        phone: this.userPhone,
        language: this.language,
        timezone: this.timezone
      }
      let message = ''
      let type = ''
      try {
        const res = await apiEditUser(data)
        if (res.status === 200) {
          await this.getUser()
          message = `${ this.$t('account_notify_edit_success') }：${ this.userId }`
          type = 'success'
        } else {
          message = `${ this.$t('account_notify_edit_failed') }：${ this.userId }`
          type = 'error'
        }
      } catch (err) {
        message = `${ this.$t('account_notify_edit_failed') }：${ err }`
        type = 'error'
      } finally {
        this.hideEditInput()
        this.$notify({
          title: this.$t('account_notify_edit_title'),
          message: message,
          type: type,
        })
      }
    },
    logOut() {
      try {
        this.logout()
        this.$router.push('/login')
      }catch(err){
        console.log(err)
      }
    },
    openPSDmodal() {
      this.psd_modal_visible = true
    },
    openLOGOUTmodal() {
      this.logout_modal_visible = true
    },
    closeLOGOUTmodal() {
      this.logout_modal_visible = false
    },
    closePSDmodal() {
      this.psd_modal_visible = false
    },
    // 雙因子認證
    openTwoFactorModal() {
      this.twoFactor_modal_visible = true
    },
    closeTwoFactorModal() {
      this.twoFactor_modal_visible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.user-info-modal {
  position: absolute;
  top: 0;
  left: px2rem(50);
  width: calc(100% - px2rem(50));
  height: 100%;
  /* background-color: rgba(32, 44, 57, 0.6); */
  z-index: 3;
}

.user-info-wrap {
  position: absolute;
  left: px2rem(50);
  bottom: 2%;
  width: 30%;
  height: calc(px2rem(694) / px2rem(768) * 100%);
  box-sizing: border-box;
  border: px2rem(8) solid $color_4A5C78;
  background: $color_151B35;
  border-radius: 0 px2rem(6) px2rem(6) 0;
  padding: px2rem(20);
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  color: $color_FFF_50;
  div {
    cursor: pointer;
  }
}

.account {
  font-size: px2rem(16);
  font-weight: bold;
}
.content-wrap {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: px2rem(5);
}

.info {
  background-color: $color_FFF;
  border-radius: px2rem(5);
  box-sizing: border-box;
  padding: px2rem(10);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: px2rem(20);
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.info-item {
  display: flex;
  align-items: center;
  line-height: px2rem(15);

  div:nth-child(2) {
    margin-left: px2rem(10);
    line-height: px2rem(15);
    flex-grow: 1;
  }

  .icon {
    img {
      width: px2rem(18);
      height: px2rem(18);
    }
  }

  .two-factor {
    display: flex;
    justify-content: space-between;

    .two-factor-st {
      display: flex;
      justify-content: flex-end;
      // background-color: #00f;
      span {
        margin-right: 1rem;
      }
      img {
        width: px2rem(20);
        height: px2rem(20);
      }
    }
  }
}

.top {
  height: 70%;
  position: relative;
}

.middle, .btm {
  height: 15%;
}

.btn-link {
  cursor: pointer;
}

.edit-pen {
  /* position: absolute; */
  top: px2rem(3);
  right: px2rem(3);
  cursor: pointer;
  color: #fff;
}

.top-btn {
  display: flex;
  justify-content: flex-end;
}

.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: px2rem(4);
  cursor: pointer;
  width: px2rem(100);
  height: px2rem(36);
  box-sizing: border-box;
  font-size: px2rem(14);
  color: #282942;
}

.confirm-btn {
  margin-left: px2rem(5);
  background-color: #343b5e;
  color: #fff;
}

.cancel-btn {
  border: 1px solid #546e95;
  color: #546e95;
}


/* .btn-wrap:hover {
  background: #ffd231;
}

.confirm-btn {
  margin-left: 5px;
  background-color: #ffc600;
}

.cancel-btn {
  border: 1px solid #ffc600;
  color: #ffc600;
}

.cancel-btn:hover {
  background: rgba(255, 198, 0, 0.2);
} */

input {
  margin-left: px2rem(10);
  padding: 0 px2rem(15);
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  line-height: px2rem(30);
  border: 1px solid #dcdfe6;
  border-radius: px2rem(4);
}

.select:deep {
  width: 100%;
  input {
    height: px2rem(32);
    font-size: px2rem(16);
  }
}
</style>
