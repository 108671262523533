<template>
  <div class="left-menu" :class="leftMenuStatus ? 'left-open' : 'left-close'">
    <div
      class="logo-wrap"
      :class="{ 'logo-offset': leftMenuStatus }"
      :title="version"
      @click="handleLeftMenu"
    >
      <template v-if="leftMenuStatus">
        <img class="banner-img" v-if="banner" :src="banner" alt="">
        <img class="banner-img" v-else src="@/assets/icons/logo.svg" alt="" />
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('collapse_device_list')/*收合設備列表*/" placement="bottom">
          <div class="icon">
            <img src="@/assets/icons/double-left.svg" />
          </div>
        </el-tooltip>
      </template>
      <template v-else>
        <img class="logo-img" v-if="logo" :src="logo" alt="">
        <img class="logo-img" v-else src="@/assets/icons/logo-b.png" alt="">
      </template>
    </div>
    <div class="content-wrap" :style="cssVars">
      <div class="icon-bar">
        <div class="container-menu-buttons">
          <div class="tp">
            <div class="tp-item-wrap" v-for="nav of mainNavList" :key="nav.key">
              <!-- function icon -->
              <el-tooltip
                :key="nav.key"
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :visible-arrow="false"
                :content="$t(nav.tooltip)"
                :disabled="currmenu && currmenu.key === nav.key ? true : false"
                placement="right"
              >
                <div class="tp-item">
                  <img :src="nav.img" alt="" @click="nav.click(nav)" />
                </div>
              </el-tooltip>
              <!-- sub-menu -->
              <div
                class="sub-menu-list"
                :id="nav.key"
                v-if="currmenu && currmenu.key === nav.key"
                v-click-outside="onCloseSubMenu"
              >
                <div class="index">
                  <i class="fas fa-caret-left outside"></i>
                  <i class="fas fa-caret-left inside"></i>
                </div>
                <div class="sub-menu">
                  <div
                    class="title"
                    :class="{ 'has-content': nav.menus ? true : false }"
                  >
                    {{ $t(nav.tooltip) }}
                  </div>
                  <div v-if="nav.menus" class="content">
                    <div
                      class="menu"
                      v-for="menu in nav.menus"
                      :key="menu.key"
                      @click="menu.click(menu)"
                    >
                      {{ $t(menu.label) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btm">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('left_bar_download_app')/*下載APP*/" placement="right">
              <div
                v-if="appDownload || manualDownload"
                class="btm-item"
                :class="{ 'btn-active': showDownloadPanel }"
                @click="toggleDownloadPanel"
              >
                <img src="@/assets/icons/DownloadCenter.svg" alt="">
              </div>
            </el-tooltip>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('left_bar_export_center')/*匯出中心*/" placement="right">
              <div
                class="btm-item"
                :class="{ 'btn-active': showExportPanel }"
                @click="toggleExportPanel"
              >
                <img src="@/assets/icons/file_download.svg" alt="" />
              </div>
            </el-tooltip>
            <!-- 電話按鈕 -->
            <el-tooltip
              v-if="permissionV2.voiceCall"
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="$t('left_bar_call') /*通話*/"
              placement="right"
            >
              <div
                id="callingBtnID"
                class="btm-item calling-btn"
                @click="setCallMode"
                @mouseover="callingHover(1)"
              >
                <img :src="groupCallImgUrl" alt="" />
              </div>
            </el-tooltip>
            <!-- 通話區塊 來電 -->
            <div
              v-if="permissionV2.voiceCall"
              class="group-call-wrap"
              v-show="answerPhoneVisible"
            >
              <div
                class="calling-img"
                @click="closeAnswerPhone(false)"
                style="margin-left: 12px"
              >
                <img src="@/assets/icons/calling-off.svg" />
              </div>
              <div
                class="calling-img"
                @click="closeAnswerPhone(true)"
                style="margin-left: 21px"
              >
                <img src="@/assets/icons/calling-answer.svg" />
              </div>
              <div class="left-user">
                <div class="left-user-name">{{ deviceNam }}</div>
              </div>
            </div>
            <!-- 通話區塊 撥出 -->
            <div
              v-if="permissionV2.voiceCall && openCallModal"
              class="group-call-wrap"
              @mouseover="callingHover(1)"
              @mouseleave="callingHover(0)"
            >
              <div class="calling-img" @click="handOff" style="margin-left:12px;">
                <img src="@/assets/icons/calling-off.svg" />
              </div>
              <div class="calling-img" @click="changeMuted" style="margin-left:21px;">
                <img v-if="muted" src="@/assets/icons/calling-mute.svg" />
                <img v-else src="@/assets/icons/calling-unmute.svg" />
              </div>
              <div class="left-user" v-if="callMode == 'calling-user'">
                <div class="left-user-name">{{ deviceNam }}</div>
                <div v-if="strCallTime == '00:00'" class="ani_dot">...</div>
                <div v-else>{{ strCallTime }}</div>
              </div>
              <div class="left-group" v-if="callMode == 'calling-group'">
                <div class="left-group-name">{{ groupNam }}</div>
                <div class="calling-glist" @click="handleGroupMemberModal">
                  <img v-if="openGroupMemberModal" src="@/assets/icons/calling-glist-dn.svg" />
                  <img v-else src="@/assets/icons/calling-glist-up.svg" />
                </div>
                <!-- 群組通話清單 -->
                <div class="group-call-list-wrap" v-if="openGroupMemberModal">
                  <!-- <i class="el-icon-close" @click="handleGroupMemberModal"></i> -->
                  <div class="group-title">
                    <div>{{ groupNam }}</div>
                    <div class="total">
                      <img src="@/assets/icons/member-num.svg" />
                      <div>({{ online_groupMemberList.length }})</div>
                    </div>
                  </div>
                  <!--   ( {{ item.id }} ) -->
                  <div class="call-list"> 
                    <div v-for="(item, index) in online_groupMemberList" :key="index">
                      {{ getVideoTitleIdByIndex(item.id, item.name) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 下載中心 -->
            <DownloadCenter v-if="showDownloadPanel" />
            <!-- 匯出中心 -->
            <ExportCenter v-if="showExportPanel" />

            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="`${getUserInfo.name}(${getUserInfo.id})` /*$t('left_bar_account')*/ /*我的帳號*/"
              placement="right"
            >
              <div
                class="btm-item"
                :class="{ 'btn-active': showUserInfo }"
                @click="handleUserInfo"
              >
                <img src="@/assets/icons/userAccount.svg" alt="" />
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="group-user-tree-wrap" :style="paddingBtm">
        <GroupUserTree @assignCallInfo="assignCallInfo" />
      </div>
      <Calling
        v-if="openCalling"
        @assignCallInfo="assignCallInfo"
        @closeCalling="closeCalling"
        :callingTop="calling_top"
      />
    </div>
    <UserInfo v-if="showUserInfo" @closeModal="closeUserInfoModal"/>
    <!--    <video :srcObject.prop="localStream"/>-->
    <div v-show="false" ref="videoBox">
      <video v-show="false" id="remoteVideo" autoplay />
    </div>
    <audio ref="audio" :src="getAudioSrc" type="audio/mp3" loop autoplay />
    <MicrophonePermission v-show="permissionDeniedVisible" @closeMP="close_pDV" />
    <!-- <el-dialog
      title="請開啟 麥克風 權限！"
      :visible.sync="permissionDeniedVisible"
      width="440px"
      height="300px">
      <img src="@/assets/icons/permission_denied.png" alt="" width="388px" height="236px">
    </el-dialog> -->
    <!-- <AnswerPhone v-show="answerPhoneVisible" @closeAP="closeAnswerPhone" /> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import vClickOutside from 'v-click-outside'
import GroupUserTree from './LeftBar/GroupUserTree'
import UserInfo from './LeftBar/UserInfo'
// import StopCallModal from './LeftBar/StopCallModal'
import {
  callNotificationWS,
  callRoomWS,
  callRoomAddressApi,
  callSdpSpeakApi,
  callSdpListenApi,
  callCmdApi,
  callRoomMemberApi,
  leaveRoom,
  callChannelApi,
  domain_name,
  apiGetUserIndex
} from '@/api/index.js'
import { createPeerConnection, openMediaDevice } from '@/api/webrtc.js'
import Calling from './LeftBar/Calling.vue'
import MicrophonePermission from './LeftBar/MicrophonePermission.vue'
// import AnswerPhone from './LeftBar/AnswerPhone.vue'
import { version } from '../../package.json'
import {
  dashboardPermission,
  historyPermission,
  recognitionPermission,
  accountPermission,
  systemPermission
} from '@/router'

export default {
  name: 'Left',
  components: {
    // StopCallModal,
    GroupUserTree,
    UserInfo,
    Calling,
    MicrophonePermission,
    // AnswerPhone,
    DownloadCenter: () => import('./LeftBar/DownloadCenter.vue'),
    ExportCenter: () => import('./LeftBar/ExportCenter.vue'),
  },
  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      currmenu: null,
      online_groupMemberList: [],
      online_nameList: [],
      openUserInfo: false,
      openGroupChannelModal: false,
      openCallModal: false, // 來電接通狀態
      openGroupMemberModal: false,
      deviceNam: '',
      groupNam: '',
      isGroup: false,
      showText_mode: '',
      chosen_nam: '',
      params_id: '',
      cmd: '',
      showStopCallModal: false,
      showUserInfo: false,
      notificationWS: null,
      roomWS: null,
      localStream: null,
      room: null,
      address: null,
      remotePeerList: {},
      localPeer: null,
      memberListFromHttp: [],
      memberListFromWS: [],
      memberListFromNotificationWS: [],
      seconds: 0, // 通話秒數
      talkTimer: null, // 計算通話timer
      audio: null,
      srcSound: 'notification', // notification/calling/ring
      muted: false,
      channels: [],
      userCallTimeout: null,
      version: `v${version}`,
      permissionDeniedVisible: false,
      answerPhoneVisible: false, // 來電響鈴狀態
      saveMsg: null,
      saveUserName: null,
      openCalling: false, 
      calling_bkColor: '#020b23',
      calling_top: 0,
      calling_glist_btn: 0,
      calling_glist_top: 0,
      calling_display: 'flex',
      calling_timeout: null
    }
  },
  mounted() {
    this.audioPause() // audio 增加 autoplay 一開始要先暫停
    this.checkResize()
    window.addEventListener('resize', this.checkResize)

    if (!this.permissionV2.dashboard) {
      this.$store.commit('updateLeftMenuOpen', false)
    }
  },
  computed: {
    ...mapState([
      'banner',
      'logo',
      'LeftMenuOpen',
      'callMode',
      'TopMenuHeightLevel',
      'eventCardDetailObj',
      'matchLiveOrigin',
      'userList',
      'permissionV2',
      'appDownload', 
      'manualDownload',
      'showDownloadPanel'
    ]),
    ...mapState('history', ['showExportPanel']),
    ...mapGetters(['getUserInfo']),
    mainNavList() {
      const list = []
      const dashboard = dashboardPermission(this.permissionV2)
      const history = historyPermission(this.permissionV2)
      const recognition = recognitionPermission(this.permissionV2)
      const account = accountPermission(this.permissionV2)
      const system = systemPermission(this.permissionV2)
      // 勤務派遣
      if (dashboard) {
        list.push({
          key: 'dashboard',
          tooltip: 'left_bar_dashboard',
          img: this.iconDashboard,
          path: '/dashboard',
          click: (nav) => this.onGoTo(nav),
          menus: [],
        })
      }
      // 事件紀錄
      if (history) {
        list.push({
          key: 'history',
          tooltip: 'left_bar_history',
          img: this.iconHistory,
          path: '/history',
          click: (nav) => this.onGoTo(nav),
          menus: []
        })
      }
      // 辨識資料庫
      const { lpr, fr /*, or*/ } = recognition
      if (recognition.ret) {
        const recogObj = { lpr, fr /*, or*/ }
        const recognitions = Object.keys(recogObj).filter(
          (key) => recogObj[key]
        )
        const recognitionDb = {
          key: 'recognition',
          tooltip: 'left_bar_recognition',
          img: this.iconRecognition,
          path: null, // '/history',
          click: (nav) => this.onGoTo(nav),
          menus: []
        }
        // 如果只有一種辨識時, 就不顯示下拉選單; 點擊會直接轉跳對應頁面
        if (recognitions.length <= 1) {
          recognitionDb.tooltip = `left_bar_${recognitions[0]}_recognition`
          recognitionDb.path = `/${recognitions[0]}_recognition`
        } else {
          recognitionDb.click = (nav) => this.onOpenSubMenu(nav)
          if (lpr) {
            recognitionDb.menus.push({
              key: 'lpr_recognition',
              label: 'left_bar_lpr_recognition',
              path: '/lpr_recognition',
              click: (menu) => this.onGoTo(menu)
            })
          }
          if (fr) {
            recognitionDb.menus.push({
              key: 'or_recognition',
              label: 'left_bar_fr_recognition',
              path: '/fr_recognition',
              click: (menu) => this.onGoTo(menu)
            })
          }
          // if (or) {
          //   recognitionDb.menus.push({
          //     key: 'or_recognition',
          //     label: 'left_bar_or_recognition',
          //     path: '/or_recognition',
          //     click: (menu) => this.onGoTo(menu)
          //   })
          // }
        }
        if (recognitionDb) list.push(recognitionDb)
      }
      // 帳號
      if (account) {
        list.push({
          key: 'account-management',
          tooltip: 'left_bar_account_management',
          img: this.iconAccount,
          path: '/account-management', // 如果有 menus 就不用 path
          click: (nav) => this.onGoTo(nav),
          menus: []
        })
      }
      // 系統
      if (system) {
        list.push({
          key: 'system-setting',
          tooltip: 'left_bar_system_setting',
          img: this.iconSystemSetting,
          path: '/system-setting', // 如果有 menus 就不用 path
          click: (nav) => this.onGoTo(nav),
          menus: []
        })
      }
      return list
    },
    cssVars() {
      return {
        "--calling-bkcolor": this.calling_bkColor,
        "--calling-top": this.calling_top + 'px',
        "--calling-display": this.calling_display,
        "--calling-glist-btn": this.calling_glist_btn + 'px',
        "--calling-glist-top": this.calling_glist_top + 'px',
      }
    },
    toggleStyle() {
      if (this.LeftMenuOpen) {
        return { transform: 'rotate(180deg)' }
      }
      return { transform: 'rotate(0deg)' }
    },
    paddingBtm() {
      if (this.callMode == 'calling-group' || this.callMode == 'calling-user') {
        return { 'padding-bottom': '100px' }
      }
      return { 'padding-bottom': '0px' }
    },
    groupCallImgUrl() {
      if (this.callMode == 'calling-group') {
        return require('@/assets/icons/group-calling-bgon.svg')
      }
      if (this.callMode == 'calling-user') {
        return require('@/assets/icons/user-calling-bgon.svg')
      }
      if (this.callMode == 'on') {
        return require('@/assets/icons/group-calling-click.svg')
      }
      return require('@/assets/icons/phone.svg')
    },
    getAudioSrc() {
      if (this.srcSound == 'notification')
        return require('@/assets/sound/notification.mp3')
      if (this.srcSound == 'calling')
        return require('@/assets/sound/calling.mp3')
      if (this.srcSound == 'ring') return require('@/assets/sound/ring.mp3')
      return require('@/assets/sound/calling.mp3')
    },
    strCallTime() {
      let m = Math.floor(this.seconds / 60)
      let s = this.seconds % 60
      return (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
    },
    leftMenuStatus() {
      // 如果不是dashboard頁面就關閉left menu, LeftMenuOpen 紀錄離開dashboard時 left menu 的狀態
      return this.$route.path !== '/dashboard' ? false : this.LeftMenuOpen
    },
    iconDashboard() {
      return this.$route.path === '/dashboard'
        ? require('@/assets/icons/lb-dashboard-hover.svg')
        : require('@/assets/icons/lb-dashboard.svg')
    },
    iconHistory() {
      return this.$route.path === '/history'
        ? require('@/assets/icons/lb-history-hover.svg')
        : require('@/assets/icons/lb-history.svg')
      // ? require('@/assets/icons/event-search-hover.svg')
      // : require('@/assets/icons/event-search.svg')
    },
    iconRecognition() {
      return this.$route.path.includes('_recognition')
        ? require('@/assets/icons/lb-recognition-hover.svg')
        : require('@/assets/icons/lb-recognition.svg')
      // ? require('@/assets/icons/subscription-hover.svg')
      // : require('@/assets/icons/subscription.svg')
    },
    iconAccount() {
      return this.$route.path === '/account-management'
        ? require('@/assets/icons/lb-account-hover.svg')
        : require('@/assets/icons/lb-account.svg')
    },
    iconSystemSetting() {
      return this.$route.path === '/system-setting'
        ? require('@/assets/icons/lb-system-hover.svg')
        : require('@/assets/icons/lb-system.svg')
    },
    isDashboard() {
      return this.$route.path === '/dashboard'
    }
  },
  methods: {
    ...mapMutations(['updateShowDownloadPanel', 'updateLeftMenuOpen']),
    ...mapMutations('history', ['updateShowExportPanel']),
    checkResize() {
      let element = document.getElementById('callingBtnID')
      this.calling_top = element?.offsetTop + 50
      this.calling_glist_btn = this.calling_top - 25
      this.calling_glist_top = this.calling_top - 379 - 7
    },
    callingHover(val) {
      if (this.openCallModal) {
        if (val==1) {
          this.calling_display = 'flex'
          this.clearCalling3sec()
        } else this.calling_display = 'none'
      }
    },
    async getChannels() {
      try {
        this.channels = []
        let res = await callChannelApi()
        res.data.forEach(item => {
          this.channels.push(item)
        })
      } catch(e) {
        console.log(e)
      }
    },
    changeMuted() {
      this.muted = !this.muted
      if (this.localStream)
        this.localStream.getAudioTracks()[0].enabled = !this.muted
    },
    async clearPeerConnection() {
      //  清除所有stream/peer connection/room/address/track.stop/ 撥打電話的15秒timeout
      this.clearCallTimeOut()
      if(this.localStream) { 
        this.localStream.getTracks().forEach((track) => {
          track.stop()
        })
      }
      this.localStream = null
      if (this.roomWS) {
        this.roomWS.close()
        this.roomWS = null
      }
      if (this.room) await leaveRoom(this.room)
      this.room = null
      this.address = null
      if(this.localPeer) {
        this.localPeer.close()
        this.localPeer = null
      }
      if(Object.keys(this.remotePeerList).length) {
        for (const i in this.remotePeerList) {
          this.remotePeerList[i].close()
        }
        this.remotePeerList = {}
      }
      this.memberListFromHttp = []
      this.memberListFromWS = []
      this.online_groupMemberList = []
      const videoBox = this.$refs.videoBox
      if (videoBox.firstChild) {
        while (videoBox.firstChild) // 因為搜尋第一個比最後一個快
          videoBox.removeChild(videoBox.lastChild)
      }
    },
    async connectNotificationWS() {
      let self = this
      // 20231024 可能因為不同瀏覽器，造成accessToken=null，所以要先判斷
      // 若無accessToken，則先呼叫refreshToken，再呼叫connectNotificationWS
      if (!this.$store.state.accessToken) {
        await this.$store.dispatch('refreshToken')
      }
      this.notificationWS = callNotificationWS(this.$store.state.accessToken)
      this.notificationWS.addEventListener('open', function () {
        console.log('notificationWS 連結建立成功')
      })
      this.notificationWS.addEventListener('close', function () {
        console.log('notificationWS 連結關閉')
        self.$store.commit('updateCallCmd', null)
      })
      // 接聽方
      this.notificationWS.addEventListener('message', async function (e) {
        let msg = JSON.parse(e.data)
        if (msg.event != 7) return
        if (msg.userId == self.getUserInfo.index) {
          // 自己送給自己的 hangup 沒有 room key
          if (self.answerPhoneVisible && !msg.payload.key) { 
            self.answerPhoneVisible = false
            self.audioPause()
          }
          return
        }
        let UserName = self.getUserName(msg.userId)
        if (!UserName) {
          let res = await apiGetUserIndex(msg.userId)
          UserName = res.data.id
        }
        self.saveUserName = UserName
        // console.log(`[LeftBar.connectNotificationWS]`, msg.payload.cmd, msg.userId, self.getUserInfo.index, self.params_id, self.room)
        self.$store.commit('updateCallCmd', msg.payload.cmd)
        switch (msg.payload.cmd) {
          case 'call':
            if (self.room == msg.payload.key) return
            if (self.answerPhoneVisible || self.openCallModal) {
              //console.log('目前響鈴中 或 通話中 拒絕接聽', msg.userId, msg.payload.key)
              self.sendNotification('hangup', '', msg.userId, msg.payload.key) // 掛斷對方電話
              return
            }
            self.deviceNam = UserName
            self.saveMsg = msg
            self.callAnswer()
            self.params_id = msg.userId // 保留第一個來電的ID 判斷是否掛斷電話用
            break
          case 'hangup':
            // 同個聊天室的人掛斷就把那個的生移除 聊天室沒人了 就自動掛斷電話
            if (self.room == msg.payload.key) {
              let uid = msg.userId
              if (self.remotePeerList[uid]) {
                self.remotePeerList[uid].close()
                delete self.remotePeerList[uid]
              }
              // hand off 沒人要掛斷
              if (Object.keys(self.remotePeerList).length == 0) {
                self.handOff()
              }
              // 已離開通話
              // if (msg.userId == self.params_id) self.$message.warning(`${UserName} ${self.$t('left_bar_left_call')}`)
              if (msg.userId == self.params_id)
                self.$notify({
                  type: 'warning',
                  message: `${UserName} ${self.$t('left_bar_left_call')}`
                })
            } else if (self.room == null) {
              // 未接通前 有可能多個人打來 所以要判斷 第一個來電的ID 要可以掛斷
              if (self.params_id == msg.userId) {
                self.handOff()
                self.params_id = null
              }
            }
            break
        }
      })
    },
    async connectRoomWS() {
      // 一對一通話：
      // 自己發出的訊息不需要處理
      // 自己打給別人，需要接受answer或hangup通知，確認對方是否接起或掛斷
      // 別人打給自己，會從另一個ws知道，所以這邊不處理call的通知

      // 群組通話：
      // 自己發出的訊息不需要處理
      // 自己加入群組聊天室，需要接受answer或hangup通知，確認對方是否接起或掛斷
      // 模式等同聊天室，不需要知道有沒有人打給這個群組電話，所以不需要處理call通知
      // 一對一聊天室只剩自己就退出
      let self = this
      this.roomWS = callRoomWS(this.room, this.$store.state.accessToken)
      // Listen for possible errors
      this.roomWS.addEventListener('error', (event) => {
        console.log('WebSocket error: ', event)
        this.room = null
      })
      await new Promise(resolve => { 
          this.roomWS.addEventListener('open', function () {
            console.log('先連上room websocket')
            resolve('roomWS 連線成功')
          })
        }
      )
      this.roomWS.addEventListener('close', function () {
        console.log('roomWS 連線關閉')
        return true
      })
      // 對方接通後
      this.roomWS.addEventListener('message', async function (e) {
        let msg = JSON.parse(e.data)
        if (msg.id == self.getUserInfo.index) return
        let UserName = self.getUserName(msg.id)
        if (!UserName) {
          let res = await apiGetUserIndex(msg.id)
          UserName = res.data.id
        }
        self.audioPause()
        switch (msg.cmd) {
          case 'answer':
            self.clearCallTimeOut()
            // self.$message.success(`${msg.name} ${self.$t('left_bar_joined_call')}`) // 已加入通話
            self.$notify({
              type: 'success',
              message: `${msg.name} ${self.$t('left_bar_joined_call')}` // 已加入通話
            })

            self.memberListFromWS = [{ id: msg.id, name: msg.name }]
            // 已經有就不重複加入了
            self.online_groupMemberList.push({id: msg.id, name: msg.name})
            await self.setRemotePeer(self.memberListFromWS)
            self.startTalkTimer()
            break
          case 'hangup':
            try {
              // self.$message.warning(`${UserName} ${self.$t('left_bar_left_call')}`) // 已離開通話
              self.$notify({
                type: 'warning',
                message: `${UserName} ${self.$t('left_bar_left_call')}` // 已離開通話
              })
              self.online_groupMemberList.splice(self.online_groupMemberList.findIndex(item => item.id == msg.id), 1)
              if (document.getElementById(`pttChannel${msg['id']}`)) {
                self.$refs.videoBox.removeChild(document.getElementById(`pttChannel${msg['id']}`))
                //self.remotePeerList[msg['id']].close()
                delete self.remotePeerList[msg['id']]
              }
              if (self.params_id) self.params_id = null
            } catch (e) {
              console.log(e)
            }
            break
        }
        if (Object.keys(self.remotePeerList).length == 0) {
          if(self.callMode == 'calling-user' || self.callMode == 'calling-group') { 
            self.handOff() 
          }
        }  
      })
    },
    startTalkTimer() {
      // 這邊要算通話時間
      if (this.talkTimer) {
        clearInterval(this.talkTimer)
        this.talkTimer = null
        this.seconds = 0
      }
      this.talkTimer = setInterval(() => {
        this.seconds++
      }, 1000)
    },
    stopTalkTimer() {
      if (this.talkTimer) {
        clearInterval(this.talkTimer)
        this.talkTimer = null
        this.seconds = 0
      }
    },
    closeModal() {
      this.showStopCallModal = false
    },
    closeUserInfoModal() {
      this.showUserInfo = false
    },
    checkChangeCallOrNot(nam) {
      this.showText_mode = 'changeCall'
      this.chosen_nam = nam
      this.showStopCallModal = true
    },
    checkHandOffOrNot() {
      this.showText_mode = 'handOff'
      this.showStopCallModal = true
    },
    async assignCallInfo(mode, nam, params_id) {
      // mode : group/user 在群組按右鍵->ptt群組通話/在user按右鍵->通話
      // nam  : 呈現在UI上的名字
      // id   : 要帶進api的參數
      // cmd  : 要帶進api的參數
      let cmd = (mode == 'group') ? 'answer' : 'call'
      // 卡關同視窗或多視窗
      // (1) 使用者如果已經在A聊天室通話中 不能在別的分頁重複開啟此通話 => 用api room member判斷
      // (2) 使用者如果已經在A聊天室 不能進入其他聊天室 => 目前因為一對一api機制 所以有擋掉 但可以同時進入多個群組聊天室
      if (this.room) {
        // this.$message.warning(this.$t('left_bar_end_call_before')) // 請先結束目前的通話，再開啟新通話
        this.$notify({
          type: 'warning',
          message: this.$t('left_bar_end_call_before') // 請先結束目前的通話，再開啟新通話
        })
        return
      }
      // 上面的判斷很容易因為使用者沒有正常掛斷，就不能再打，
      // 若room有值則先進行掛斷的動作，讓接下來重新通話
      // if (this.room) await this.handOff()
      // 當１對１通話時，才判斷是否撥打給自己
      if (mode == 'user' && params_id == this.getUserInfo.index) {
        // this.$message.warning(this.$t('left_bar_cant_call_myself')) // 不能打電話給自己
        this.$notify({
          type: 'warning',
          message: this.$t('left_bar_cant_call_myself') // 不能打電話給自己
        })
        return
      }
      if(mode == 'user') { 
        this.audioPlay('calling')
      }
      this.startCall(mode, nam, params_id, cmd)
    },
    callTimeout(mode, cmd) {
      // 一對一通話 15秒沒人接聽 就自動掛斷
      if(mode == 'user' && cmd == 'call') {
        this.userCallTimeout = setTimeout(() => { 
          // this.$message.warning(this.$t('left_bar_15_seconds_hangup')) // 15秒沒人接聽 自動掛斷通話
          this.$notify({
            type: 'warning',
            message: this.$t('left_bar_15_seconds_hangup') // 15秒沒人接聽 自動掛斷通話
          })

          this.handOff() 
        }, 1000*15)
      }
    },
    async startCall(mode, nam, params_id, cmd, channel=false) {
      // 一對一通話 15秒沒人接聽 就自動掛斷 有人接聽 或自己按掛斷 就清掉timeout
      //console.log('start call: mode = ', mode, ', name = ', nam, ', id = ', params_id, ', cmd = ', cmd)
      this.params_id = params_id
      this.callTimeout(mode, cmd)
      // 1. 取得room和address 從ws(有人打電話來)或呼叫api取得
      if (cmd == 'call' || mode == 'group') { 
        if(!channel) { await this.getRoomAddress(params_id, mode) } else {
          this.room = params_id
          this.address = domain_name
        }
      }
      // 2. 取得room和address後
      // (1) 連接room的ws 接收該room的接通或掛斷通知 一對一中 對方掛斷 自己這邊也要掛斷
      // 注意 要有人連接room websocket以後 才會真的建立房間喔
      await this.connectRoomWS() 
      // (2) 取得room現有member 如果是一對一通話 room裡面已經有自己 就不能再開啟通話
      let getRoomMemberRes = await this.getRoomMember(mode, cmd)
      if(!getRoomMemberRes) { 
        this.clearPeerConnection()
        this.audioPause()
        return
      }
      // 3. 建立local peer connection
      this.localPeer = createPeerConnection(this.address)
      // 4. 建立localStream 加到localPeer 交換speak的sdp 發送電話通知(接通、掛斷或打出去)
      // (1)  建立localStream
      this.localStream = await openMediaDevice()
      if (!this.localStream) {
        //this.$message.warning('連接麥克風失敗，中斷通話連線!')
        this.permissionDeniedVisible = true
        this.clearPeerConnection()
        this.audioPause()
        return
      }
      // (2) 加到localPeer
      this.addLocalStreamToLocalPeer()
      // (3) 交換speak的sdp 發送電話通知(接通、掛斷或打出去)
      let changeSdpSpeakRes = await this.changeSdpSpeakAndSendNotification(cmd, mode, params_id, this.room)
      if (!changeSdpSpeakRes) {
        // this.$message.warning(this.$t('left_bar_failed_sdp_speak')) // 交換sdp speak或發送語音通知失敗，中斷通話連線
        this.$notify({
          type: 'warning',
          message: this.$t('left_bar_failed_sdp_speak') // 交換sdp speak或發送語音通知失敗，中斷通話連線
        })
        this.clearPeerConnection()
        this.audioPause()
        return
      }
      // 5. 建立remotePeer listen sdp
      let setRemotePeerRes = await this.setRemotePeer(this.memberListFromHttp)
      if (!setRemotePeerRes) {
        console.log('setRemotePeer 失敗 中斷通話連線')
        this.clearPeerConnection()
        this.audioPause()
        return
      }
      // 6. 開啟通話跳窗
      if (mode == 'group') {
        this.handleGroupCallModal(nam)
      } else {
        this.handleUserCallModal(nam)
      }
    },
    addLocalStreamToLocalPeer() {
      this.localStream.getTracks().forEach((track) => {
        this.localPeer.addTrack(track, this.localStream)
      })
    },
    async setRemotePeer(memberList) {
      for (let member of memberList) {
        this.remotePeerList[member.id] = createPeerConnection(this.address)
        let peer = this.remotePeerList[member.id]
        this.localStream.getTracks().forEach((track) => {
          peer.addTrack(track, this.localStream) // 將自己的軌道傳給遠端
        })
        let changeSts = await this.changeSdpListen(peer, member.id)
        if (!changeSts) return false
      }
      return true
    },
    async changeSdpListen(peer, target) {
      let offer = await peer.createOffer()
      peer.setLocalDescription(offer)
      let answer = await this.getSdpListenAnswer(offer, target)
      if (!answer) return false // 交換失敗就不繼續下去
      peer.setRemoteDescription(new RTCSessionDescription(answer))
      peer.ontrack = (e) => { // 透過監聽事件即時的將別人的軌道加進我們這邊放遠端訊號的MediaStream
        if (e && e.streams) {
          let temp = document.createElement('video')
          temp.id = `pttChannel${target}`
          temp.srcObject = e.streams[0]
          temp.autoplay = true
          temp.style.display = 'none'
          this.$refs.videoBox.appendChild(temp)
          temp = null
        }
      }
      return true
    },
    async getSdpListenAnswer(offer, target) {
      try {
        let res = await callSdpListenApi(this.room, offer, target)
        return res.data.answer
      } catch (e) {
        console.log(e)
        return null
      }
    },
    async getRoomMember(mode, cmd) {
      try {
        let res = await callRoomMemberApi(this.room)
        if(mode == 'user' && res.data.members.length) {
          let onThePhone = res.data.members.find(item => item.id == this.getUserInfo.index)
          // 如果使用者正在通話中 不能在別的網頁開啟新通話
          if(onThePhone) {
            // this.$message.warning(this.$t('left_bar_another_webpage_call')) // 您已經在別的網頁開啟通話中，必須關閉才能重新開啟通話
            this.$notify({
              type: 'warning',
              message: this.$t('left_bar_another_webpage_call') // 您已經在別的網頁開啟通話中，必須關閉才能重新開啟通話
            })
            return false
          }
        }
        if(mode == 'user' && cmd == 'answer' && !res.data.members.length) {
          // 如果接起電話後 發現對方已經掛斷了 就不開啟通話
          // this.$message.warning(this.$t('left_bar_other_has_hungup')) // 對方已掛斷電話
          this.$notify({
            type: 'warning',
            message: this.$t('left_bar_other_has_hungup') // 對方已掛斷電話
          })
          return false
        }
        if(mode == 'group' && res.data.members.length ) {
          res.data.members.forEach(member => {
            this.online_groupMemberList.push(member)
          })
        }
        this.memberListFromHttp = res.data.members
        return true
      } catch (e) {
        // this.$message.error('Not found room')
        this.$notify({
          type: 'error',
          message: 'Not found room'
        })
        return false
      }
    },
    async sendNotification(cmd, mode = '', params_id = '', room_key = '') {
      try {
        this.$store.commit('updateCallCmd', cmd)
        await callCmdApi(this.reformNotificationData(cmd, mode, params_id, room_key))
        return true
      } catch (e) {
        console.log('傳送通知失敗')
        return false
      }
    },
    getMemberList(mode, params_id) {
      if (mode == 'group') {
        let arr = []
        this.userList.forEach((user) => {
          if (user.groupId == params_id) arr.push(String(user.index))
        })
        return arr
      } else {
        return [params_id].map(String)
      }
    },
    getUserName(userId) {
      try {
        return this.userList.find((user) => user.index == userId).video.title
      } catch {
        return ''
      }
    },
    // 以index取得video.title + id
    getVideoTitleIdByIndex(index, name) {
      try {
        let user = this.userList.find((user) => user.index == index)
        return `${user.video.title} (${user.id})`
      } catch {
        return `${name}` //  (${index})
      }
    },
    getVideoTitleIdById(id) {
      let user = this.userList.find((user) => user.id == id)
      return `${user.video.title} (${user.id})`
    },
    reformNotificationData(cmd, mode, params_id, room_key) {
      let data = {}
      if (cmd == 'hangup') { // this.getUserInfo.index, 
        let ids = []
        if (this.getUserInfo.index) ids.push(this.getUserInfo.index)
        if (params_id) ids.push(params_id)
        ids = ids.map(String)
        let payload = {}
        if (room_key) payload = { address: this.address, cmd, key: room_key }
        else payload = { address: this.address, cmd }
        data = {
          ids,
          content: {
            event: 7,
            payload
          }
        }
      } else {
        data = {
          ids: params_id
            ? this.getMemberList(mode, params_id)
            : [this.getUserInfo.index].map(String),
          content: {
            event: 7,
            payload: { address: this.address, cmd }
          }
        }
      }
      if(mode == 'group') {
        data.ids = [this.getUserInfo.index].map(String)
      }
      if (cmd == 'hangup') {
        //console.log('reformNotificationData', data.ids, data)
        return data
      } else {
        data.content.payload.key = this.room
        data.content.payload.address = this.address
        return data
      }
    },
    async changeSdpSpeakAndSendNotification(cmd, mode, params_id, room_key) {
      let offer = await this.localPeer.createOffer()
      this.localPeer.setLocalDescription(offer)
      let answer = await this.getSdpSpeakAnswer(offer)
      if (!answer) {
        console.log('取得sdp speak answer失敗 中斷通話')
        this.clearPeerConnection()
        return false
      }
      this.localPeer.setRemoteDescription(new RTCSessionDescription(answer))
      let sendNotificationSts = this.sendNotification(cmd, mode, params_id, room_key)
      if (!sendNotificationSts) {
        console.log('發送語音通知失敗 中斷通話')
        this.clearPeerConnection()
        return false
      }
      return true
    },
    async getSdpSpeakAnswer(offer) {
      try {
        let res = await callSdpSpeakApi(
          this.room,
          offer,
          this.getUserInfo.index
        )
        return res.data.answer
      } catch (e) {
        if (e.response) {
          console.error(e.response.status)
          console.error(e.response.data)
        } else if (e.request) {
          console.error(e.request)
        } else {
          console.error('Error: ', e.message)
        }
        return null
      }
    },
    async getRoomAddress(params_id, mode) {
      // params_id: 帶入的參數 mode: 是不是group
      try {
        let res = await callRoomAddressApi(params_id, mode)
        this.room = res.data.key
        this.address = res.data.address
        //console.log('取得room/address => ', this.room, this.address)
      } catch (e) {
        console.log('呼叫/webrtc失敗')
      }
    },
    clearCallTimeOut() {
      clearTimeout(this.userCallTimeout)
      this.userCallTimeout = null
    },
    async handOff() { // 按下掛斷電話
      this.clearCallTimeOut()
      await this.endCall()
      this.muted = false
      this.$store.commit('updateCallMode', 'off')
      this.$store.commit('updateVideoCall', false) // for video call
      this.openGroupChannelModal = false
      this.openGroupMemberModal = false
      this.openCallModal = false
      this.answerPhoneVisible = false
      this.stopTalkTimer()
      this.audioPause()
    },
    handleGroupCallModal(nam) {
      this.groupNam = nam
      this.$store.commit('updateCallMode', 'calling-group')
      this.openCallModal = true
      this.openGroupChannelModal = false
      this.openGroupMemberModal = false
    },
    async handleUserCallModal(nam) {
      this.deviceNam = nam
      this.$store.commit('updateCallMode', 'calling-user')
      this.openCallModal = true
      this.openGroupChannelModal = false
      this.openGroupMemberModal = false
      // 接聽後 發一個 hangup 給自己 然後排除自己 為了區別 這裡不傳 this.room
      this.sendNotification('hangup', '', this.getUserInfo.index, '')
    },
    async endCall() {
      let cmd = 'hangup'
      if (this.room) {
        await this.sendNotification(cmd, '', this.params_id, this.room)
      } else {
        await this.sendNotification(cmd)
      } 
      this.clearPeerConnection()
    },
    setCallMode() {
      // 點擊phone icon要打開左邊跳窗 並出現團體頻道pop up menu 再點擊一次就消失
      if (this.callMode == 'calling-group') {
        this.$store.commit('updateCallMode', 'off')
        this.handOff()
        return
      }
      if (this.callMode == 'calling-user') {
        this.$store.commit('updateCallMode', 'off')
        this.handOff()
        return
      }
      this.openCalling = !this.openCalling
      this.checkPanel('openCalling')
      if (this.openCalling) this.calling_bkColor = '#4A5C78'
      else this.calling_bkColor = '#020b23'
      return
    },
    handleGroupMemberModal() {
      // 開關 群組通話清單modal
      this.openGroupMemberModal = !this.openGroupMemberModal
    },
    handleLeftMenu() {
      // dashboard 頁面才打開或關閉LeftMenu
      if (this.isDashboard) {
        this.updateLeftMenuOpen(!this.LeftMenuOpen)
      } 
      this.checkPanel('LeftMenuOpen')
    },
    onGoTo(menu) {
      if (this.currmenu) this.onCloseSubMenu()
      if (this.$route.path !== menu.path) {
        let path = menu.path
        this.$router.push({ path })
      }
    },
    toggleDownloadPanel() {
      this.updateShowDownloadPanel(!this.showDownloadPanel)
      this.checkPanel('showDownloadPanel')
    },
    toggleExportPanel() {
      const newVal = !this.showExportPanel
      this.updateShowExportPanel(newVal)
      this.checkPanel('showExportPanel')
    },
    checkPanel(type) {
      if (type != 'showDownloadPanel') {
        this.updateShowDownloadPanel(false)
      }
      if (type != 'showExportPanel') {
        if (this.showExportPanel) {
          const newVal = !this.showExportPanel
          this.updateShowExportPanel(newVal)
        }
      }
      if (type != 'openCalling') {
        if (this.openCalling) {
          this.openCalling = !this.openCalling
          this.calling_bkColor = '#020b23'
        }
      }
      if (type != 'showUserInfo') {
        if (this.showUserInfo) {
          this.showUserInfo = false
        }
      }
    },
    handleUserInfo() {
      this.showUserInfo = !this.showUserInfo
      this.checkPanel('showUserInfo')
    },
    audioPlay(sound) {
      try {
        this.srcSound = sound // notification, calling, ring
        this.$refs.audio.play()
      } catch (e) {
        console.log(e)
      }
    },
    audioPause() {
      try {
        this.$refs.audio.pause()
        this.$refs.audio.currentTime = 0
      } catch (e) {
        console.log(e)
      }
    },
    closeCalling() {
      this.openCalling = false
      this.calling_bkColor = '#020b23'
    },
    clearCalling3sec() {
      clearTimeout(this.calling_timeout)
      this.calling_timeout = null
    },
    close_pDV() {
      this.permissionDeniedVisible = false
    },
    async closeAnswerPhone(type) {
      this.answerPhoneVisible = false
      if (type) { // 按下接聽電話
        // 如果已經在通話中 要中斷原先通話 再開啟新通話
        if(this.room) {  await this.handOff() }
        this.room = this.saveMsg.payload.key
        this.address = this.saveMsg.payload.address
        this.startCall(
          'user',
          this.saveUserName,
          this.saveMsg.userId,
          'answer'
        )
        this.startTalkTimer()

        this.$store.commit('updateCallCmd', 'answer')
      } else { // 按下拒絕接聽
        this.room = this.saveMsg.payload.key
        this.address = this.saveMsg.payload.address
        await this.startCall(
          'user',
          this.saveUserName,
          this.saveMsg.userId,
          'answer'
        )
        //console.log('拒絕接聽 送出拒接的訊息 讓對方掛斷 或 跟目前一樣什麼都不做 因為15秒沒人就會自動掛斷')
        if (this.room) { 
          await this.handOff()
          this.$store.commit('updateCallCmd', 'handup')
        }
      }
      this.audioPause()
    },
    callAnswer() {
      this.answerPhoneVisible = true
      this.calling_display = 'flex' // 這裡要顯示 不然接通後 撥號放掛斷 不會顯示
      this.audioPlay('ring')
    },
    checkNav(nav) {
      switch (nav) {
        case 'dashboard':
          return this.permissionV2.dashboard > 0
        case 'history':
          return this.permissionV2.lprEventAccess > 0
        case 'subscription':
          return this.permissionV2.lprSummary > 0
        case 'account-management':
          return (
            this.permissionV2.account ||
            this.permissionV2.device ||
            this.permissionV2.group ||
            this.permissionV2.roleManagement
          )
        case 'system-setting':
          return this.permissionV2.system === 1
        default:
          return false
      }
    },
    onOpenSubMenu(nav) {
      if (this.currmenu && this.currmenu.key === nav.key) {
        this.onCloseSubMenu()
        return
      }
      this.currmenu = {
        key: nav.key,
        title: nav.title,
        menus: [...nav.menus]
      }

      // 調整menu位置
      this.$nextTick(() => {
        const menu = document.querySelector(`#${nav.key}`)
        const gap = this.currmenu.menus ? this.currmenu.menus.length * 15 : 0
        const y = -140 + gap
        menu.style.transform = `translateY(${y}%)`
      })
    },
    onCloseSubMenu() {
      this.currmenu = null
    }
  },
  watch: {
    // left bar監聽top bar狀態 如果top bar關起來的時候 有正在播放的直播影片 就銷毀LiveEvent.vue並把url設回空string
    // 如果這個直播影片是從right bar點開的 就要把eventDetailObj也設回空物件
    TopMenuHeightLevel() {
      if (this.TopMenuHeightLevel == 1) {
        if (this.matchLiveOrigin == 'rightBar') {
          this.$store.commit('updateEventCardDetailObj', {})
        }
        this.$store.commit('updateLiveEventOn', {
          sts: false,
          url: '',
          origin: ''
        })
        this.$store.commit('updateLockDeviceId', '') // 清空鎖定的設備ID
      }
    },
    callMode(val) {
      let sec3 = false
      if (val == 'calling-group' || val == 'calling-user') {
        this.calling_bkColor = '#8CC24D'
        sec3 = true
      } else {
        this.calling_bkColor = '#020b23'
      }
      if (sec3) {
        this.calling_display = 'flex'
        this.calling_timeout = setTimeout(() => {
            this.calling_display = 'none'
        }, 3000)
      }
    },
    $route: {
      handler() {
        this.updateShowDownloadPanel(false)
        this.updateShowExportPanel(false)
      },
      deep: true,
      immediate: true
    },
  },
  created() {
    setTimeout(() => {
      this.connectNotificationWS()
    }, 2000)

    this.$bus.$on('sosCall', this.assignCallInfo)
    this.$bus.$on('videoCall', this.assignCallInfo)
    this.$bus.$on('videoHandOff', this.handOff)

    this.getChannels()
  },
  beforeDestroy() {
    if (this.room) this.handOff()
    if (this.notificationWS) this.notificationWS.close()
    this.$bus.$off('sosCall', this.assignCallInfo)
    this.$bus.$off('videoCall', this.assignCallInfo)
    this.$bus.$off('videoHandOff', this.handOff)
    window.removeEventListener('resize', this.checkResize)
  }
}
</script>

<style lang="scss" scoped>
$NavBarW: 50px; // 固定, 不以rem轉換
$NavIconWH: px2rem(40);

.left-menu {
  height: 100%;
  max-width: px2rem(326);
  transition: all 0.3s;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.left-close {
  grid-area: start / start / end / left-close;
}
.left-open {
  grid-area: start / start / end / left-open;
}
.logo-wrap {
  position: relative;
  box-sizing: border-box;
  background-color: #151b35;
  display: flex;
  justify-content: flex-start;
  padding-left: px2rem(8);
  align-items: center;
  min-height: $NavBarW;
  min-width: $NavBarW;
  overflow: hidden;
}
.logo-wrap.logo-offset {
  justify-content: center;
}

.banner-img {
  width: px2rem(130);
  height: px2rem(32);
}

.logo-img {
  width: px2rem(32);
  height: px2rem(32);
}

.content-wrap {
  box-sizing: border-box;
  background-color: #151b35;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  right: px2rem(12);
  transform: translateY(-50%);
  width: px2rem(32);
  height: px2rem(32);
  border-radius: px2rem(8);
  background: #ffffff33;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon:hover {
  background: #ffffff80;
}

.icon-bar {
  width: $NavBarW;
  min-width: $NavBarW;
  box-sizing: border-box;
  background-color: #36795f;
}
.group-user-tree-wrap {
  box-sizing: border-box;
  width: calc(100% - $NavBarW);
  height: 100%;
}

@mixin iconBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 45%;
  min-height: px2rem(225);
  background-color: #020b23;
}

@mixin iconItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: px2rem(40);
  height: px2rem(40);
  cursor: pointer;
}

.tp {
  @include iconBar;
  justify-content: flex-start;
  // margin-top: $NavIconWH;
  margin-top: min($NavIconWH, $NavIconWH/2);
  height: 45%;

  .tp-item-wrap {
    position: relative;
    .tp-item {
      @include iconItem;
      // margin-bottom: $NavIconWH;
      margin-bottom: min($NavIconWH, $NavIconWH/2);
    }

    .sub-menu-list {
      $SubMenuTtitleBGC: #4a5c78;
      position: fixed; // 固定在視窗上
      left: calc($NavBarW - px2rem(8));
      display: flex;
      align-items: center;
      color: #fff;
      z-index: 5;
      .index {
        svg:first-child {
          font-size: px2rem(20);
          color: #151b35;
          transform: translate(px2rem(10), px2rem(-3));
        }
        svg:last-child {
          font-size: px2rem(28);
          color: $SubMenuTtitleBGC;
        }
      }

      .sub-menu {
        border-radius: 0.5rem;
        background-color: #151b35;

        .title {
          color: #ffe99f;
          padding: px2rem(5) 4rem px2rem(5);
          border-radius: px2rem(8);
          border: 1px solid $SubMenuTtitleBGC;
          background-color: $SubMenuTtitleBGC;
          &.has-content {
            border-radius: px2rem(8) px2rem(8) 0 0;
          }
        }
        .content {
          border: 1px solid $SubMenuTtitleBGC;
          background-color: #151b35;
          border-radius: 0 0 px2rem(8) px2rem(8);
          .menu {
            padding: px2rem(5) 4rem px2rem(5);
            cursor: pointer;
            &:hover {
              background-color: #4a5c7880;
            }
          }
        }
      }
    }
  }
}

.btm {
  @include iconBar;
  height: 40%;
  justify-content: flex-end;
  // margin-bottom: $NavIconWH;
  margin-bottom: min($NavIconWH, $NavIconWH/2);

  .btm-item {
    @include iconItem;
    $btnItemBtm: calc($NavIconWH - ($NavBarW - $NavIconWH));

    justify-content: center;
    // margin-top: $btnItemBtm;
    margin-top: min($btnItemBtm, $btnItemBtm/2);
    // background-color: #f00;

    // &:nth-child(1),
    // &:nth-child(2),
    // &:last-child {
    height: $NavBarW;
    width: 100%;
    img {
      width: px2rem(32);
      height: px2rem(32);
    }
    // }
  }
}
.btn-active {
  background: #4a5c78;
}

.hide {
  opacity: 0;
  cursor: default;
}
.tp-title {
  font-size: 12px;
  -webkit-transform: scale(0.58);
  transform: scale(0.58);
  // letter-spacing: 0.5px;
  color: #ffffff;
  margin-top: px2rem(-2);
}
.container-menu-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: px2rem(50);
  width: 50px;
  height: 100%;
  background-color: #020b23;
  overflow-y: auto;
}
.calling-btn {
  width: px2rem(50);
  height: px2rem(50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--calling-bkcolor);
}
.group-call-wrap {
  position: fixed;
  width: px2rem(327);
  height: 50px;
  background: #8cc24d 0% 0% no-repeat padding-box;
  border-radius: px2rem(6);
  opacity: 1;
  box-sizing: border-box;
  top: var(--calling-top);
  left: 0px;
  z-index: 99;
  display: var(--calling-display);
  align-items: center;
  color: #fff;
}
.group-call-wrap .right div {
  cursor: pointer;
}
.calling-img {
  width: px2rem(37);
  height: px2rem(37);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.left-user {
  width: px2rem(210);
  height: px2rem(32);
  text-align: left;
  // font: normal normal normal 24px/32px Roboto;
  font-size: px2rem(24);
  line-height: px2rem(32);
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
  margin-left: px2rem(18);
  display: flex;
  justify-content: row;
}
.left-user-name {
  width: px2rem(130);
  height: px2rem(32);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left-group-name {
  width: px2rem(192);
  height: px2rem(32);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left-group {
  width: px2rem(210);
  text-align: left;
  // font: normal normal normal 24px/32px Roboto;
  font-size: px2rem(24);
  line-height: px2rem(32);
  letter-spacing: 0;
  color: #FFFFFF;
  opacity: 1;
  margin-left: px2rem(18);
  display: flex;
  justify-content: row;
}
.group-call-list-wrap {
  position: fixed;
  width: px2rem(255);
  height: px2rem(379);
  top: var(--calling-glist-top);
  left: px2rem(50);
  padding-left: px2rem(10);
  color: #fff;
  background: #151B35 0% 0% no-repeat padding-box;
  border: px2rem(6) solid #8CC24D;
  border-radius: px2rem(6) px2rem(6) px2rem(6) 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.group-call-list-wrap i {
  align-self: flex-end;
  cursor: pointer;
}
.call-list {
  height: px2rem(250);
  font-size: px2rem(18);
  overflow: scroll;
}
.group-title {
  color: #ffd000;
}
.calling-glist {
  position: fixed;
  width: px2rem(58);
  height: px2rem(25);
  left: px2rem(50);
  top: var(--calling-glist-btn);
  background: #8CC24D 0% 0% no-repeat padding-box;
  border-radius: px2rem(6) px2rem(6) 0 0;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}
.total {
  display: flex;
  font-size: px2rem(12);
  cursor: pointer;
}
.group-channel-options-wrap {
  box-sizing: border-box;
  width: calc(100% - px2rem(50));
  height: px2rem(300);
  overflow-y: scroll;
  padding-top: px2rem(35);
  padding-bottom: px2rem(35);
  background-color: #232e4d;
  position: absolute;
  bottom: 0;
  left: px2rem(50);
  z-index: 1;
}
.options-wrap {
  width: 100%;
  padding: px2rem(10) px2rem(30) px2rem(10) px2rem(15);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.options-wrap:hover {
  background-color: #384979;
}
.options-wrap .right {
  visibility: hidden;
}
.options-wrap:hover .right {
  visibility: visible;
}
.options-wrap .left {
  display: flex;
  gap: px2rem(5);
}
::-webkit-scrollbar {
  width: px2rem(6);
  height: px2rem(6);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 px2rem(4) rgba(204, 204, 204, 0.1);
  box-shadow: inset 0 0 px2rem(4) rgba(204, 204, 204, 0.1);
  background: #151b35;
  border-radius: px2rem(4);
}
::-webkit-scrollbar-thumb {
  border-radius: px2rem(4);
  width: px2rem(4);
  height: px2rem(4);
  background-color: #596486;
  -webkit-box-shadow: inset 0 0 px2rem(4) rgba(63, 62, 62, 0.5);
  box-shadow: inset 0 0 px2rem(4) rgba(63, 62, 62, 0.5);
}
::-webkit-scrollbar-corner {
  background: #151b35;
}
.ani_dot {
  font-weight: bold;
  font-size: px2rem(28);
  margin-left: px2rem(15);
  opacity: 1;
}
:root .ani_dot { /* 這裡使用Hack是因為IE6~IE8瀏覽器下， vertical-align解析不規範，值為bottom或其他會改變按鈕的實際高度*/
  display: inline-block;
  width: 1.5em;
  /* vertical-align: middle; */
  overflow: hidden;
  padding-bottom: px2rem(14);
}
@-webkit-keyframes dot {
  0% { width: 0; margin-right: 1.5em; }
  33% { width: .5em; margin-right: 1em; }
  66% { width: 1em; margin-right: .5em; }
  100% { width: 1.5em; margin-right: 0;}
}
.ani_dot {
  --webkit-animation: dot 3s infinite step-start;
}
@keyframes dot {
  0% { width: 0; margin-right: 1.5em; }
  33% { width: .5em; margin-right: 1em; }
  66% { width: 1em; margin-right: .5em; }
  100% { width: 1.5em; margin-right: 0;}
}
.ani_dot {
  animation: dot 3s infinite step-start;
}

.fade-enter-active, .fade-leave-active{                 
  transition: opacity .5s;                              
}
.fade-enter, .fade-leave-to {           
  opacity: 0;
}
</style>
