<template>
  <div class="switch">
    <label v-if="prefix" :for="uid" class="label prefix">{{ prefix }}</label>
    <div>
      <input
        :id="uid"
        class="input"
        type="checkbox"
        v-model="switchValue"
      />
      <label :for="uid" class="slider"></label>
    </div>
    <label v-if="suffix" :for="uid" class="label suffix">{{ suffix }}</label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: ['prefix', 'suffix', 'value'],
  data() {
    return {
      uid: 'toggle-switch-' + Math.random().toString(36).substr(2, 9)
    }
  },
  computed: {
    switchValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSwitch() {
      const val = !this.value
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  align-items: center;
  height: 30px;
}

.switch .label {
  font-size: 20px;
  color: #ffffff;
  margin-right: 16px;
  line-height: 28px;
  user-select: none;
  cursor: pointer;
}

.prefix {
  margin-right: 16px;
}

.suffix {
  margin-left: 16px;
}

.switch .slider {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 25px;
  border-radius: 20px;
  cursor: pointer;
  background: #546267;
  transition: all 0.2s cubic-bezier(.75,.05,.07,1.05);
}
.switch .slider:after {
  background: #fefefe;
  position: absolute;
  left: -8px;
  top: -2px;
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  content: '';
  transition: all 0.2s;
}
 
.switch .input {
  display: none;
}

.switch .input:checked ~ .slider:after {
  left: 16px;
}
.switch .input:checked ~ .slider {
  background: #FFC600;
}
</style>