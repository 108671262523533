<template>
  <div class="wrap-microphone">
    <div class="microphone-inner">
      <div class="title">
        <div style="width:100%; display: flex; align-items: center; margin: 0 0 0 32px;">
          <img src="@/assets/icons/warn-icon.svg" style="width:24px; height:24px;">
          <span class="titxt">麥克風權限未啟用</span>
        </div>
        <img src="@/assets/icons/closeWin.svg" @click="closeIt" style="cursor: pointer; margin-right: 24px;">
      </div>
      <div class="txt1">請開啟麥克風權限。</div>

      <div class="line1">
        <div class="box1">
          <div class="num1">
            <span class="numtxt1">1</span>
          </div>
          <div class="txt2">點擊網址列右上角圖示</div>
        </div>
        <div class="box2">
          <div class="num1">
            <span class="numtxt1">2</span>
          </div>
          <span class="txt2">勾選"一律允許存取麥克風"並點擊"完成"</span>
        </div>
      </div>

      <div class="line2">
        <img src="@/assets/icons/microphoneP1.svg" style="width:430px; height:342px; margin: 24px 0 0 60px;">
        <img src="@/assets/icons/microphoneP2.svg" style="width:430px; height:342px; margin: 24px 0 0 80px;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MicrophonePermission',
  props: [ '' ],
  data() {
    return {

    }
  },
  methods: {
    closeIt() {
      this.$emit('closeMP', false)
    },
    
  }
}
</script>

<style scoped>
.wrap-microphone {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.microphone-inner {
  width: 1065px;
  height: 608px;
  background: #282942 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
}
.title {
  width: 1065px;
  height: 54px;
  background: #4A5C78 0% 0% no-repeat padding-box;
  border-radius: 23px 23px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border:.1px gray dashed; */
}
.titxt {
  width: 100%;
  height: 31px;
  text-align: left;
  /* font: normal normal normal 23px/30px Roboto; */
  font-size: 23px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 22px;
  /* border:.1px gray dashed; */
}
.body1 {
  height: 69px;
}
.txt1 {
  width: 1000px;
  height: 43px;
  text-align: left;
  /* font: normal normal normal 32px/43px Roboto; */
  font-size: 32px;
  line-height: 43px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin: 26px 0 0 60px;
  /* border:.1px gray dashed; */
}
.line1 {
  display: flex;
  flex-direction: row;
  /* border:.1px gray dashed; */
}
.box1 {
  width: 430px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 29px 0 0 60px;
  /* border:.1px gray dashed; */
}
.num1 {
  width: 30px;
  height: 30px;
  background: #4A5C78 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border:.1px gray dashed; */
}
.numtxt1 {
  width: 13px;
  height: 30px;
  text-align: left;
  /* font: normal normal normal 23px/30px Roboto; */
  font-size: 23px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  /* border:.1px gray dashed; */
}
.txt2 {
  width: 100%;
  height: 31px;
  text-align: left;
  /* font: normal normal normal 20px/30px Roboto; */
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 8px;
  /* border:.1px gray dashed; */
}
.box2 {
  width: 435px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 29px 0 0 80px;
  /* border:.1px gray dashed; */
}

.line2 {
  height: 402px;
  display: flex;
  flex-direction: row;
  /* border:.1px gray dashed; */
}
</style>